
.index {
  box-sizing: border-box;
  height: 100%;
  padding: 20px 20px 0;
}
.resources-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .management-admin-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    height: 50px;
  }
 
}

.select-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  .select-left {
       display: flex;
        align-items: center;
        margin-bottom: 20px;
    .text {
      font-size: 14px;
      color: #333333;
      margin-right: 10px;
    }
    .resources-cascader {
      width: 420px;
      ::v-deep .el-input__inner {
        border-radius: 0;
      }
    }
  }
  .select-btn {
    i {
      font-size: 12px;
      margin-right: 5px;
    }
  }
 
}
.scro{
  height: 80%;
  .el-scrollbar {
          height: 100%;
  }

}
.customTable th.el-table__cell {
  background-color: #f0f3fc !important;
}
.dialog-footer {
  text-align: center;
  margin-top: 20px;
}
.pages-center {
  margin: 20px 0 20px;
  text-align: center;
}
.demo-ruleForm .el-form-item__content{
  display:inline-block;
}
.demo-form-inline{
      display: flex;
    flex-direction: column;
    padding: 0 30px;
}
.el-dialog__title {
      border-left: 1px solid #000 !important;
}

